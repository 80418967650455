import React from "react"

export default function Acquista() {
  return (
    <section className="section-acquista">
      <div className="container">
        <div className="columns is-centered is-mobile">
          <div className="column">
            <h1>
              RETRO
              <br />
              MARKETING
            </h1>
            <h3>Il Marketing strategico tra nostalgia e innovazione</h3>
            <p className="mb-6">
              <a
                rel="noreferrer"
                target="_blank"
                href="https://www.amazon.it/marketing-marketing-strategico-nostalgia-innovazione/dp/B0BG8H5JVM/ref=sr_1_2?qid=1670337044&refinements=p_27%3ADaniela+Bavuso&s=books&sr=1-2"
              >
                Acquista
              </a>
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}
